import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Nav from './containers/navbar/App.jsx';
import { CalenderContextProvider } from './Context/calender.context';

const rootElement = document.getElementById('body');

ReactDOM.render(
  <GoogleOAuthProvider clientId="583147330281-vtsctaf82qn2kerhsgl6nq5qvi9u7v0h.apps.googleusercontent.com">
    <CalenderContextProvider>
      <Nav />
    </CalenderContextProvider>
  </GoogleOAuthProvider>,
  rootElement
);
