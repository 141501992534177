import { UseMedia } from 'hooks/useMedia';
import { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { googleSheetApi } from '../../constants/index'
import {
  FOOTER,
  JudgesInfo,
  Prizeinfo,
  TOP_SECTION,
  frequentlyAskedQuestions,
  sponsorLogos
} from '../../Module/General';
import { Logo, LogoSectionAbout } from '../../components/About/index.jsx';
import { Accordion } from '../../components/Accordian/index.jsx';
import Birds from '../../components/Animation';
import Footer from '../../components/Footer/index.jsx';
import { Myinfo } from '../../components/Landing/index.jsx';
import { FirstPrize, PrizeHeading } from '../../components/Prizes/index.jsx';
import Media from '../../components/Socials/index.jsx';
import {
  Sponsor,
  SponsorUS,
  SponsorsHead
} from '../../components/Sponsors/sponsors.jsx';
import { JoinTeam, Member } from '../../components/Team';
import './about.css';
import pattern from './assets/pattern4.png';
// import hackFestVideo from './assets/SCHackfest23.mp4';
import Register from 'components/Registration';
import TeamInfo from 'components/TeamInfo';

// Prize group
const PrizeGroup = (props, index) => {
  return (
    <Row key={index}>
      {props.map((s, i) => (
        <Col key={i} className="" sm={12} lg={4} md={4}>
          <FirstPrize icon={s.icon} type={s.type} content={s.content} />
        </Col>
      ))}
    </Row>
  );
};

const FrequentlyAsked = (props, index) => {
  return (
    <Row key={index} className="sf">
      {props.map((s, i) => (
        <Col key={i} sm={12} lg={6} md={6}>
          <Accordion panels={s} />
        </Col>
      ))}
    </Row>
  );
};

export default function HomePage({ tokenInfo, login }) {
  const [media, setMedia] = useState();
  const [open, setOpen] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [teamInfo, setTeamInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const registerCb = () => {
    setIsRegistered(true)
  }

  useEffect(() => {
    setIsLoading(true)
    fetch(`${googleSheetApi}?action=getUsers`).then(res => res.json()).then(data => {
      setAllUsers(data)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    let currentUser = allUsers.find((elm) => elm.email === tokenInfo.email)
    if (currentUser) {
      currentUser.teamMates = []
      allUsers.forEach(user => {
        if (user.teamName === currentUser.teamName) {
          currentUser.teamMates.push(user)
          if (user.isCaptain === 'YES') {
            currentUser.ideaTitle = user.ideaTitle
            currentUser.ideaBrief = user.ideaBrief
          }
        }
      })
      setTeamInfo(currentUser)
      setIsRegistered(true)
    }
  }, [tokenInfo, allUsers])

  UseMedia('min-width', 1000, setMedia);

  return (
    <div className="Whole_div" style={{ backgroundImage: `url(${pattern})` }}>
      <div className="color_sectiom" id="home">
        <Container fluid>
          <Row className="Row info">
            <Col className="info-div" sm={12} lg={7} md={7}>
              <Myinfo showDrawer={showDrawer} tokenInfo={tokenInfo} isRegistered={isRegistered} login={login} isLoading={isLoading} />
            </Col>
            <Col className="d-image" sm={12} lg={5} md={5}>
              <TeamInfo teamInfo={teamInfo} />
            </Col>
          </Row>
          {
            tokenInfo.hd && tokenInfo.hd === 'sharechat.co' &&
            <Register open={open} onClose={onClose} tokenInfo={tokenInfo} registerCb={registerCb} allUsers={allUsers} />
          }
        </Container>
      </div>
      <Container fluid>
        {/* Logo section  */}
        <Row className=" logoSection">
          <Col className="info-div" sm={12} lg={8} md={8}>
            <LogoSectionAbout />
          </Col>
          <Col className="info-div" sm={12} lg={4} md={4}>
            <Logo />
          </Col>
        </Row>
        <Row>
          <video width="1200" controls>
            <source src='https://hackathon.sharechat.com/SCHackfest23.mp4' type="video/mp4" />
          </video>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <div className="LogoSectionAbout">
            <h1>What’s in it for the you?</h1>
            {
              ['SCHackFest is an opportunity for all associates to stand out and showcase their skills. Hackathons provide valuable experience in high-pressure situations, enhancing decision-making skills in challenging circumstances. In addition to showcasing technical skills, participation will help you improve soft skills such as precise communication, leadership, teamwork, and mentoring abilities.', 'There are several prizes to be won, including cash awards and a chance to put your name in the Hackathon trophy forever.', 'Did we tell you about a cool hackathon t-shirt? :) '].map((elm, index) => {
                return <><p key={index}>{elm}</p><br></br></>
              })
            }
          </div>
        </Row>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Hack themes </h1>
            <h2>Open Category</h2>
            {
              ['You can work on any idea that has the potential to deliver a 10x impact on the business or technology for the organisation. This idea could be aligned with a specific product or business unit, or it could focus on improving development productivity. Alternatively, you can explore new product ideas that align with the overall business vision.', 'You can create an MVP using any new technology if it aligns with the business goals. For example, you could explore re-imagining social media on the Web3 interface.', 'An idea that will not meet the criteria is one that lacks relevance to the business. For instance, ideas related to battery technology (EV), warehouse optimisation, or credit-aligned financial technology would not align with our current focus.'].map((elm, index) => {
                return <><p key={index}>{elm}</p><br></br></>
              })
            }
          </div>
        </Row>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Dates</h1>
            {
              ['The SCHackFest 2.0 starts on 20th December 2023, 10 AM IST.', 'Ends on 21st December 2023,10 AM IST.', 'The judging and grand finale are on the 21st from 1030 AM IST onwards.'].map((elm, index) => {
                return <><p key={index}>{elm}</p></>
              })
            }
          </div>
        </Row>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Format</h1>
            {
              ['This will be a 24-hour hackathon open to the entire ShareChat workforce.', 'Any number of folks can form a team. Ideal guidance is 3-4. But if someone wants to go solo, go ahead. More folks are also acceptable.'].map((elm, index) => {
                return <><p key={index}>{elm}</p></>
              })
            }
          </div>
        </Row>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Submissions & Judging </h1>
            {
              ['Teams that have a working demo can present a hack centred around the theme that provide business or tech impact for ShareChat or Moj. There will be 2 rounds of evaluation. In the first round multiple jury panels of 2-3 members will go through submissions slotted to their panel and rank the hacks as per the judging criteria from their group. The top ranked hacks will go through to grand jury round for final presentations.'].map((elm, index) => {
                return <><p key={index}>{elm}</p></>
              })
            }
          </div>
        </Row>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Judging Criteria</h1>
            {
              [
                {
                  header: 'Originality & Creativity',
                  content: 'How novel and innovative is the idea? Does it aim to achieve something unique or, at the very least, enhance existing models?'
                },
                {
                  header: 'Presentation & Delivery',
                  content: 'How clear was the presentation? Was the idea and demo presented clearly?'
                },
                {
                  header: 'Impact',
                  content: 'What is the value proposition, and how does it differentiate itself? How will it capture the market, expand its audience, generate revenue, improve retention, and increase engagement? Is this something the community would genuinely use and desire? What metrics would it impact?'
                },
                {
                  header: 'Working Prototype',
                  content: 'How realistic is the idea? How close is it to making it production? Is it primed to handle scale? Additional points for those solving for edge cases.'
                },
                {
                  header: 'Technical Complexity',
                  content: 'Is it technically impressive? Complex? How technically sophisticated/advanced/elegant was the solution? Better the elegance, higher the point.'
                }
              ].map((elm, index) => {
                return <><p key={index}><b>{elm.header}</b></p><p className='tab'>{elm.content}</p></>
              })
            }
            <br></br>
            <p>Note: Each criterion has equal weight.</p>
            <p>Please note that only demonstrable hacks will be eligible for prizes. Ideas or PowerPoint presentations will not qualify for prizes.</p>
          </div>
        </Row>

        <br></br>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Grand Finale</h1>
            {
              ['We will have two rounds of presentations. In the first round, teams will present to a panel of 2/3 members, and the winner will advance to the final grand jury round. The final round will consist of a CXO panel selecting the top 7 teams. The top teams will receive cash prizes, and the winning team name will be etched into the trophy.'].map((elm, index) => {
                return <><p key={index}>{elm}</p></>
              })
            }
          </div>
        </Row>

        <br></br>

        <Row>
          <div className="LogoSectionAbout" id="schedule">
            <h1>Schedule</h1>
            {[
              {
                'title': 'Registrations Open',
                'time': '10th Nov'
              },
              {
                'title': 'Hackathon Starts',
                'time': '20th Dec 10:00 AM'
              },
              {
                'title': 'Registration Close',
                'time': '21st Dec 00:00 AM'
              },
              {
                'title': 'Submissions Close',
                'time': '21st Dec 10:00 AM'
              },
              {
                'title': 'Preliminary Judging Starts',
                'time': '21st Dec noon Onwards'
              },
              {
                'title': 'Grand Jury',
                'time': '21st Dec 5 PM Onwards'
              },
              {
                'title': 'Best Hack Winner Announcement',
                'time': '21st Dec 7 PM'
              }
            ].map((elm, index) => {
              return <div className='dFlex' key={index}>
                <p><b>{elm.title} : </b></p>
                <p> &nbsp;&nbsp;{elm.time}</p>
              </div>
            })}
          </div>
        </Row>

        {/* ********Prizes here ***** */}
        <Row className="prizesection" id="prizes">
          <PrizeHeading type="Prize section" />
          {Prizeinfo.map(PrizeGroup)}
        </Row>
        {/* ********Prizes ending here ***** */}

        <br></br>
        <br></br>
        <br></br>

        <Row>
          <div className="LogoSectionAbout">
            <h1>Additional Info</h1>
            {
              ['We encourage hackathon participants from the Bangalore office to attend the hackathon in the office for a better experience.', 'Team members who are working from home can join the hackathon remotely. They are welcome to participate in the office as well, although they need to pay for the transport costs.', 'The hackathon is open to all employees from all functions.', 'T-shirts will only be given to participants who are in the office at the time of the hackathon.', 'For any additional information, please contact Arya Ketan or Sarang Brahme.'].map((elm, index) => {
                return <><p key={index}>{elm}</p></>
              })
            }
          </div>
        </Row>

        <br></br>
        <br></br>

        {/* ********Frequently asked Questions here ***** */}
        <Row>
          <div className="Myfaqs" id="faq">
            <h1>Frequently Asked Questions</h1>
            {frequentlyAskedQuestions.map(FrequentlyAsked)}
            {/* ********Frequently asked Questions ending here ***** */}
          </div>
        </Row>

        {media && <Birds top="120vh" left="0vh" type="" />}

      </Container>
      <Footer />
    </div>
  );
}
