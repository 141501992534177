import { TOP_SECTION } from '../../Module/General';
import MytypedComponent from '../Typed/index.js';
import { googleFormLink } from '../../constants/index'
import './style.css';

const Btn = props => {
  return (
    <button className={props.class}>
      <i className={props.ico}></i>
      {props.type}
    </button>
  );
};

const About = ({ tokenInfo }) => {
  return (
    <div className="AboutMe">
      {tokenInfo.name ? <><h2>Welcome,</h2><h2>{tokenInfo.name}</h2></> : <h2>{TOP_SECTION.TITLE}</h2>}
      <MytypedComponent />
    </div>
  );
};

const Myinfo = ({ showDrawer, tokenInfo, isRegistered, login, isLoading }) => {

  const redirectToGoogleForm = () => {
    window.open(googleFormLink, "_blank");
  }

  return (
    <div className="Myinfo">
      <About tokenInfo={tokenInfo} />
      <p> {TOP_SECTION.SHORT_DESCRIPTION}</p>
      {
        tokenInfo.hd && tokenInfo.hd === 'sharechat.co' && !isRegistered && !isLoading && <div className="buttom-group">
          <div onClick={showDrawer} style={{ marginRight: '10px' }}>
            {' '}
            <Btn class="sponsor_btn" type="Register" />
          </div>
          <div onClick={() => redirectToGoogleForm()}>
            {' '}
            <Btn class="sponsor_btn" type="Interested to participate, yet to find a team?" />
          </div>
        </div>
      }
      {
        !tokenInfo.hd && !isRegistered && <div className="buttom-group">
          <div onClick={() => login()}>
            {' '}
            <Btn class="sponsor_btn" type="Sign In to Register" />
          </div>
        </div>
      }
      {
        isRegistered && <div>
          <Btn class="sponsor_btn" type="Your registration is completed! 🚀" />
        </div>
      }
    </div>
  );
};

export { Btn, Myinfo };

