import './style.css';

const TeamInfo = ({ teamInfo }) => {

    if (!teamInfo.email) return null

    return <>
        <div className='teamInfo'>
            <div><b>Your Team:</b> {teamInfo.teamName}</div>
            <div>Your Team has sucessfully submitted the Idea.</div>
            <div><b>Idea Title:</b> {teamInfo.ideaTitle}</div>
            <div><b>Idea Brief:</b> {teamInfo.ideaBrief}</div>
            <div><b>Team Members :</b></div>
            {
                teamInfo.teamMates && teamInfo.teamMates.length > 0 &&
                teamInfo.teamMates.map((user, index) => {
                    return <><div key={index}>{index + 1}. {user.name}{user.isCaptain === 'YES' && <span> (Captain)</span>}
                    &nbsp;- {user.org}
                    </div></>
                })
            }
        </div>
    </>
}

export default TeamInfo