import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Input, InputNumber, message, Select, AutoComplete } from 'antd';
import { googleSheetApi } from '../../constants/index'
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const { TextArea } = Input;


const Register = ({ open, onClose, tokenInfo, registerCb, allUsers }) => {

    const [allTeams, setAllTeams] = useState([])
    const [teamType, setTeamType] = useState('newTeam');
    const [options, setOptions] = useState([]);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const allTeamsData = allUsers.filter(obj => obj.isCaptain === 'YES').map(elm => {
            return { value: elm.teamName }
        })
        setAllTeams(allTeamsData)
        setOptions(allTeamsData)
    }, [allUsers])

    const onFinish = (values) => {
        if (values.ideaTitle && values.ideaBrief) {
            values.isCaptain = 'YES'
        } else {
            values.isCaptain = 'NO'
        }

        if (teamType === 'joinTeam') {
            const isExist = allTeams.some((obj) => {
                return obj.value === values.teamName
            })

            if (!isExist) {
                message.error("Selected Team in not exist");
                return;
            }
        }

        if (teamType === 'newTeam') {
            const isExist = allTeams.some((obj) => {
                return obj.value === values.teamName
            })

            if (isExist) {
                message.error("Team Name already exist, please choose different name!");
                return;
            }
        }

        setIsLoading(true)
        fetch(`${googleSheetApi}?action=addUser`, {
            method: 'POST',
            body: JSON.stringify(values)
        }).then(() => {
            form.resetFields();
            setIsLoading(false)
            message.success("Registration Successful!");
            registerCb()
            onClose()
        }).catch(err => {
            setIsLoading(false)
            message.error("Something Went Wrong, please try again!");
            onClose()
            console.error('err: ', err);
        })
    };

    const onTeamTypeChange = (value) => {
        setTeamType(value)
    }

    const getPanelValue = (searchText) => {
        if (searchText === "") return allTeams
        if (!allTeams?.length) return []
        return allTeams?.filter((elm) => {
            return elm?.value?.includes(searchText)
        })
    }

    return (

        <>
            <Drawer title="Register" placement="right" onClose={onClose} open={open} size='large'>
                <Form
                    {...layout}
                    form={form}
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                    validateMessages={validateMessages}
                >
                    <Form.Item
                        name={['name']}
                        label="Name"
                        initialValue={tokenInfo.name}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input defaultValue={tokenInfo.name} disabled />
                    </Form.Item>
                    <Form.Item
                        name={['email']}
                        label="Email"
                        initialValue={tokenInfo.email}
                        rules={[
                            {
                                type: 'email',
                            },
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input defaultValue={tokenInfo.email} disabled />
                    </Form.Item>
                    <Form.Item
                        name={['org']}
                        label="Org"
                        initialValue={'Eng/DS'}
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Select
                            defaultValue="Eng/DS"
                            style={{ width: '100%' }}
                            options={[
                                { value: 'Eng/DS', label: 'Eng/DS' },
                                { value: 'Product', label: 'Product' },
                                { value: 'Design', label: 'Design' },
                                { value: 'Content-Ops', label: 'Content-Ops' },
                                { value: 'Others', label: 'Others' },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            ...layout.wrapperCol,
                            offset: 8,
                        }}
                    >
                        <Select
                            defaultValue="newTeam"
                            style={{ width: '100%' }}
                            onChange={onTeamTypeChange}
                            options={[
                                { value: 'newTeam', label: 'Create New Team' },
                                { value: 'joinTeam', label: 'Join Team' },
                            ]}
                        />
                    </Form.Item>
                    {teamType === 'newTeam' ? <Form.Item
                        name={['teamName']}
                        label="Team Name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item> :
                        <Form.Item
                            name={['teamName']}
                            label="Select Team"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <AutoComplete
                                options={options}
                                style={{ width: '100%' }}
                                onSearch={(text) => setOptions(getPanelValue(text))}
                                placeholder="input here"
                            />
                        </Form.Item>
                    }
                    {teamType === 'newTeam' &&
                        <>
                            <Form.Item
                                name={['ideaTitle']}
                                label="Idea Title"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['ideaBrief']}
                                label="Idea Brief"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </>
                    }
                    <Form.Item
                        name={['location']}
                        label="Participating From"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            options={[
                                { value: 'SC BLR Office (VTP)', label: 'ShareChat Bangalore Office (VTP)' },
                                { value: 'Remote', label: 'Remote' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['size']}
                        label="Tshirt Size"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            options={[
                                { value: 'S', label: 'S' },
                                { value: 'M', label: 'M' },
                                { value: 'L', label: 'L' },
                                { value: 'XL', label: 'XL' },
                                { value: 'XXL', label: 'XXL' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            ...layout.wrapperCol,
                            offset: 8,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};
export default Register;