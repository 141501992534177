import './style.css';

import { MIDDLE_SECTION } from '../../Module/General';

function LogoSectionAbout() {
  return (
    <div className="LogoSectionAbout">
      <h1>{MIDDLE_SECTION.TITLE}</h1>
      <p>Here's a throwback to our last <a rel="noreferrer" href='https://sharechat.com/blogs/life-at-sharechat/problem-solving-innovative-solutions-and-plenty-of-fun-schackfest-1-0' target='_blank'>SCHackFest</a>.</p>
      {
        MIDDLE_SECTION.LONG_DESCRIPTION.map((elm, index) => {
          return <><p key={index}>{elm}</p><br></br></>
        })
      }
    </div>
  );
}

function Logo() {
  return (
    <div className="logoS">
      <img className="Logo" src={MIDDLE_SECTION.LOGO} alt="Limbohacks" />
    </div>
  );
}

export { Logo, LogoSectionAbout };
