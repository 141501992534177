export const googleSheetApi = 'https://script.google.com/macros/s/AKfycbwHRpZRC9RAza-nRIoKjlj3eIDjhemTLNI8OmfKsmIJpkxAB0Jc0BXUGhAchSlhZi01cg/exec'

export const googleFormLink = 'https://forms.gle/hQrEGwPXcMa5NbLb6'

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

export const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

export const CALENDER_VIEW = 1;
export const EVENT_VIEW = 2;
