import { useEffect, useRef, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Flex, message } from "antd";
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import HomePage from '../Home/HomePage';
import logoClose from './assets/ham-c.svg';
import hamLogo from './assets/ham.svg';
import './styles.scss';

import { MdxContent } from '../Mdx';

const NAVIGATION_OFFSET = 66;

message.config({
  top: "10vh",
  maxCount: 1,
});

const Wrapper = styled.div`
  display: block;
  width: 40%;

  @media (max-width: 1000px) {
    margin: 0;
    display: ${props => (props.toggle ? 'none' : 'static')};
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: ${props => (props.toggle ? '-1000px' : '0px')};
    transition: top 1s;
    .nav-content {
      height: 35%;
      background-color: rgba(50, 13, 136);
    }
  }
`;

// const MdxContent = () => {
//   return (
//     <div
//       style={{
//         backgroundColor: 'red'
//       }}
//     >
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>
//       <h1>This is blog</h1>

//     </div>
//   );
// };

const NAVBAR = ({ }) => {
  const [toggle, setToggle] = useState(true);
  const [isOffset, setIsOffset] = useState(false);
  const [tokenInfo, setUserInfo] = useState({});

  const navigation = useRef();

  const listenScrollEvent = e => {
    if (window.scrollY >= NAVIGATION_OFFSET) {
      setIsOffset(true);
    } else {
      setIsOffset(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const handleOutsideCick = (event, ref) => {
    if (!ref.current.contains(event.target)) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: userInfo => {
      const { hd } = userInfo
      if (hd === 'sharechat.co') {
        localStorage.setItem('tc_gcs', userInfo.access_token)
        fetch(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${userInfo.access_token}`)
          .then(res => res.json())
          .then(
            allUserData => {
              message.success("Logged In Successfully")
              setUserInfo({ ...userInfo, ...allUserData })
            })
          .catch((err) => {
            message.error("Something went wrong, please try again!")
            setUserInfo({})
          })
      } else {
        message.error("Please Use ShareChat Mail ID")
        setUserInfo({})
      }
    }
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('tc_gcs')
    if (accessToken) {
      fetch(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${accessToken}`)
        .then(res => res.json())
        .then(
          allUserData => {
            if (allUserData.hd === 'sharechat.co') {
              setUserInfo({ ...allUserData })
            } else {
              setUserInfo({})
            }
          })
        .catch((err) => {
          message.error("Token Expired, Please Login Again!")
          setUserInfo({})
        })
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleOutsideCick(e, navigation)
    );

    return () => {
      document.removeEventListener('mousedown', e =>
        handleOutsideCick(e, navigation)
      );
    };
  }, []);

  return (
    <Router>
      <nav className={`nav_bar ${isOffset && 'nav_bar-offset-crossed'}`}>
        <Wrapper toggle={toggle} style={{ width: '100%' }}>
          <div className="nav-content" ref={navigation}>
            <ul>
              <li>
                <Link to={`#home`} className="sc-logo-parent">
                  <img className='sc-logo' src='https://sharechat.com/assets/webp/logo-wide.webp' alt='' />
                  <img className='mj-logo' src='https://sharechat.com/assets/webp/moj-logo.webp' alt='' />
                </Link>
              </li>
              <li>
                <Link to={`#home`}>
                  <span className="links">Home </span>{' '}
                </Link>
              </li>
              <li>
                <Link to={`#schedule`}>
                  <span className="links">Schedule </span>{' '}
                </Link>
              </li>
              <li>
                <Link to={`#faq`}>
                  <span className="links">FAQs </span>{' '}
                </Link>
              </li>
              {
                tokenInfo.hd && tokenInfo.hd === 'sharechat.co' ? <li>
                  <div style={{ display: 'flex' }}>
                    <span className="links google-btn"><img className='userIcon' src={tokenInfo.picture} alt='userIcon' />{tokenInfo.email}</span>{' '}
                  </div>
                </li> : <li>
                  <div onClick={() => login()}>
                    <span className="links google-btn">Sign In with Google </span>{' '}
                  </div>
                </li>
              }
              <img
                className="s-close"
                onClick={() => setToggle(true)}
                src={logoClose}
              />
            </ul>
          </div>
          <div className="ease" />
        </Wrapper>
        <img
          className="s-open"
          onClick={() => setToggle(false)}
          src={hamLogo}
        />
      </nav>

      <Switch>
        <Route path="/blog" exact={true}>
          <MdxContent />
        </Route>
        <Route path="/" exact={true}>
          <HomePage tokenInfo={tokenInfo} login={login} />
        </Route>
      </Switch>
    </Router>
  );
};

export default NAVBAR;
