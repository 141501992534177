import hackathonLogo from './Assets/sc-hackfest-logo.png';

// Importing all sponsors logos from Assets
/** Put all your Team member image inside folder `Team Images`
 * and import them like in the above and update src in TeamInfo
 * or you can provide a cloud link in src also
 */

import boy from './Assets/boy.png';
import pass from './Assets/sponsorsLogos/1pass.png';
import ACF from './Assets/sponsorsLogos/ACF.png';
import cfc from './Assets/sponsorsLogos/cfc.png';
import echoAR from './Assets/sponsorsLogos/echoAR.png';
import egg from './Assets/sponsorsLogos/egg.png';
import glimpse from './Assets/sponsorsLogos/glimpse.png';
import interviewC from './Assets/sponsorsLogos/interview-cake.jpg';
import ll from './Assets/sponsorsLogos/ll.png';
import qoom from './Assets/sponsorsLogos/qoom.svg';
import Replit from './Assets/sponsorsLogos/replit.png';
import sublime from './Assets/sponsorsLogos/sublime.png';
import taskade from './Assets/sponsorsLogos/taskade.png';
import lyin from './Assets/teami/lyin.jpg';

/** Put all your sponsors logo inside folder `sponsorLogos`
 * and import them in the above and update `src` in sponsorLogos
 * or you can provide a cloud link in src
 */

// Importing all Team members images  from Assets

import me from './Assets/teami/me.png';
import moon from './Assets/teami/moon.png';
import Ryah from './Assets/teami/Ryah.jpg';
import zoha from './Assets/teami/zoha.png';

const TOP_SECTION = {
  TITLE: 'Login To Join SCHackFest 2.0',
  Typed_effect: ['24 hours of creation', 'Think | Code | Hack | Repeat'],
  SHORT_DESCRIPTION:
    'Scale Ideas to Reality',
  IMG_SRC: boy,
  DISCORD_LINK: '',
  JUDGES_FORM_LINK:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__jZdNhdUNVRQMUg4VllMRURDN1lON0haNFVCQUgySC4u',
  HACKERS_REGISTRATION_FORM_LINK:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__jZdNhdUNTgyREk0VzdEMU9RVFlPMVNWVEJQUkNYMi4u'
};

const SOCIALS = {
  instagram: 'https://www.instagram.com/limbohacks/',
  discord: 'https://discord.com/invite/8XJSzmtWPp',
  linkedin: 'https://www.linkedin.com/company/limbo-hacks/',
  twitter: 'https://twitter.com/HacksLimbo',
  devpost: 'https://limbo-hacks-12968.devpost.com/',
  email: 'mailto:hello@limbohacks.tech',
  mail: 'hello@limbohacks.tech'
};

const MIDDLE_SECTION = {
  TITLE: 'About',
  LONG_DESCRIPTION: [
    'The SCHackFest hackathon is a chance for all ShareChat employees to ideate in an unconstrained environment, cross-collaborate with colleagues, and aim to deliver moonshot ideas that create a 10x impact on the ecosystem. True to our value of ‘Ownership’, the hackathon gives the associates a platform to show off their coding skills, challenge their colleagues and compete to deliver the best hack across the organisation.',
    'A successful result can also give a sense of accomplishment, which may or may not come from completing your day-to-day activities. Additionally, many of the hacks may also go into production, which gives a great sense of gratification.',
    'And let’s not forget that ShareChat exists because our three co-founders met at a hackathon event. The Hackathon could well lead to something big. 😇'],
  LOGO: hackathonLogo
};

const FOOTER = {
  VOLUNTEERING_FORM: {
    required: true,
    src: 'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__jZdNhdUNFBQME44TzJZWDc2UFgwTUtLN0xDQVYyUi4u'
  },
  JOIN_TEAM: {
    required: true,
    src: 'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__jZdNhdUOUhEQkJSRk02OEU4SlBMTUdYOFIxOTNZSy4u'
  },
  Privacy_policy: {
    required: true,
    src: ''
  },
  Terms_of_use: {
    required: true,
    src: ''
  }
};

/** SCHEDULE
 *
 * INSTRUCTIONS
 *
 * Date format : 8-11-2023, 18-11-2023 (without trailing zeros)
 */

const calenderStartingDate = {
  month: 11,
  year: 2023
};

const schedule = [
  {
    day: '30-11-2023',
    events: [
      {
        title: 'Intro to phycycology',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '30-11-2023',
    events: [
      {
        title: 'Intro to deep learning',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      },
      {
        title: 'Intro to deep learning',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '30-11-2023',
    events: [
      {
        title: 'Intro to phycycology',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '30-11-2023',
    events: [
      {
        title: 'Intro to deep learning',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '30-11-2023',
    events: [
      {
        title: 'Intro to phycycology',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  }
];

/** ICON
 *
 * icons are from fontawesome visit = https://fontawesome.com
 * and just copy paste icon links and update icon in Prizeinfo
 *
 * INSTRUCTIONS
 *
 * Insert only 3 or less then 3 prizes per array in `Prizeinfo`, you can
 * create as many arrays you want all prizes will be rendered
 * in a group of 3.
 */

const Prizeinfo = [
  [
    //Array 1
    {
      icon: <i className="first fas fa-4x fa-trophy"></i>,
      type: 'Winner’s Team',
      content:
        '2,50,000 INR'
    },
    {
      icon: <i className=" second fas fa-4x fa-medal"></i>,
      type: '2nd Ranking Team',
      content:
        '1,25,000 INR'
    },
    {
      icon: <i className=" second fas fa-4x fa-medal"></i>,
      type: '3rd Ranking Team',
      content:
        '75,000 INR'
    },
    {
      icon: <i className=" second fas fa-4x fa-medal"></i>,
      type: 'From 4th to 7th Ranking',
      content:
        '25,000 INR'
    }
  ]
];

/** Instructions
 * Insert only 3 or less then 3 members per array in `TeamInfo`, you can
 * create as many arrays you want all team members will be rendered
 * in a group of 3.
 */

const TeamInfo = [
  [
    //Array 1
    {
      Name: 'Rehan',
      role: 'Organizer',
      github: 'https://github.com/Limbo-Hacks/Hackathon-website-template',
      linkedin: '',
      img: me
    },
    {
      Name: 'Moon',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: moon
    },
    {
      Name: 'Erika',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: Ryah
    }
  ],
  [
    //Array 2
    {
      Name: 'Lyanola',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: lyin
    },
    {
      Name: 'Zoheb',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: zoha
    }
  ]
];

const JudgesInfo = [
  [
    //Array 1
    {
      Name: 'Rehan',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: me
    },
    {
      Name: 'Moon',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: moon
    },
    {
      Name: 'Ryah Garcia',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: Ryah
    }
  ],
  [
    //Array 2
    {
      Name: 'Lyanola',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: lyin
    },
    {
      Name: 'Zoheb',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: zoha
    }
  ]
];

/** Instructions
 * Only put 3 logos per array inside `sponsorLogos`, you can
 * create as many arrays you want all logos will be rendered
 * in a group of 3.
 */

const sponsorLogos = [
  [{ src: cfc }, { src: sublime }, { src: echoAR }], //Array 1
  [{ src: taskade }, { src: Replit }, { src: qoom }], //Array 2
  [{ src: interviewC }, { src: pass }, { src: glimpse }], //Array 3
  [{ src: egg }, { src: ll }, { src: ACF }] //Array 4
];

/** Instructions
 * Follow the strcuture provided below to add as many as FAQ's you want
 */
const frequentlyAskedQuestions = [
  [
    [
      {
        label: 'What is the primary intention of the Hackathon?',
        content:
          "To foster an environment creating Moonshot / 10x ideas for ShareChat's current business problems."
      },
      {
        label: 'Who can attend?',
        content:
          'SCHackFest is open to all employees at ShareChat.'
      },
      {
        label: 'I am a first time hacker, what should I do?',
        content:
          'No worries, we love to welcome first time hackers and start their hacking journey from a hackathon. We have some awesome workshops and events which will help you get started with hackathon and hacking in general.'
      },
      {
        label: 'How does Team Formation work?',
        content:
          "If you have an idea and not a team, then register here with create a team option. You can then discover others with skill-sets on #schackfest2 channel. If you dont have an idea, but would love to participate then, click on the Partition button and update your details on the Google form, some one might contact you. Once you are have figured out your team, come back and register with Join a team option. Any number of folks can form a team. Ideal guidance is 3-4. But if someone wants to go solo, go ahead. More folks are also acceptable."
      },
      {
        label: 'How to Register Myself in Hackathon?',
        content:
          'All you need is to fill out the form above and join #schackfest2 Slack channel; we will guide you through everything on the channel.'
      },
      {
        label: 'What is the Duration of the event?',
        content:
          '24 hours of hack time. Check the event timeline for details. The next 4-5 hours will be a presentation/demo.'
      },
      {
        label: 'Will the company sponsor travel and accommodation expenses for the associates?',
        content:
          'No.'
      }
    ],
    [
      {
        label: 'How will judging and prize distribution happen?',
        content: 'There are 2 rounds of evaluation. Both rounds will be judged in hybrid mode ( keeping up with the direction taken by the company). In the first round, your team will present the demo to a panel of 2-3 judges. These judges will then select the winner from their group. The winner then proceeds to a grand-jury comprising top leadership who will then select the winner. Announcement and distribution will be made in hybrid mode with live event streaming over Zoom.'
      },
      {
        label: 'Can I do the hack out of any of the offices?',
        content: 'You can, but only Bangalore office will be open for 24 hours. We strongly recommend to come to bangalore office to experience a vibrant hack night with very high energy levels'
      },
      {
        label: 'What do I need to bring if I am working out of the office?',
        content: 'You need to carry your Office Laptop. The organisation will provide food and Bevergages'
      },
      {
        label: 'Can I participate in multiple teams or multiple themes?',
        content: 'No, you cannot. One person can only participate from one team.'
      },
      {
        label: 'I have more questions.',
        content: 'Please write to the team on the Slack channel (#schackfest2). You will be added to the Slack channel post-registration.'
      },
      {
        label: 'Can I be a Volunteer?',
        content: 'Yes, we are actively looking for volunteers. Please get in touch with the hackathon organising team.'
      },
      {
        label: 'Will there be Swag?',
        content: 'A cool hackathon t-shirt will be shared on the hack day. Through the hackathon event, there will be fun gaming events and a few other surprises.'
      },
      {
        label: 'Will there be food available during the event?',
        content: "Yes, we will arrange the food for the participants. For people joining remotely, they can order for food and claim reimbursements. Managers will approve the basis of what seems logically the right amount for 1 person's consumption per meal"
      }
    ]
  ]
];

export {
  calenderStartingDate, FOOTER, frequentlyAskedQuestions, JudgesInfo, MIDDLE_SECTION, Prizeinfo, schedule, SOCIALS, sponsorLogos,
  TeamInfo, TOP_SECTION
};

